import "./App.css";
import React from "react";
import {authManager} from "@common/authentication/";
import {JurisdictionUnitDetail} from "./components/JurisdictionUnitDetail";
import * as _ from "lodash";

function App() {
    const info = authManager.getInfoFromAdmin();
    const canAccess = _.get(info, "viewPermission", false);

    if (!canAccess) {
        return <React.Fragment />;
    }

    return (
        <div className="admin-app">
            <div className="container-fluid">
                <div className="row">
                    <div className="container-custom">
                        <JurisdictionUnitDetail />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
